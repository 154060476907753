import * as React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import utility from '../../classes/utility';
import AlertDialog from '../common/alertDialog';
import DataTable from '../common/DataTable/DataTable';
import { Config } from '../../classes/config';
import AssetMap from '../AssetSearch/AssetMap';
import AssetMapforDetail from '../AssetSearch/AssetMapforDetail';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextBox from 'devextreme-react/text-box';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import Spinner from '../common/reusableComponents/Spinner';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import MultiSelectTreeView from '../common/reusableComponents/MultiSelectTreeView';
import CheckBoxInput from '../common/reusableComponents/CheckBox';
import NumberBoxInput from '../common/reusableComponents/NumberBox';
let _items: any = [];
let selectedtab: any = 0;
export default class Assetsearch extends React.Component<any, any> {
  public assetitems: any = [];
  public selectedAsset: any = [];
  public AssetType: any = [];
  public selectedAssets: any = [];
  public setupDataPromise: any = [];
  public columns: any;
  constructor(props: any) {
    super(props);
    this.assetitems = [];
    this.selectedAsset = [];
    this.AssetType = [];
    this.setupDataPromise = [];
    this.getAssetwithcriteria = this.getAssetwithcriteria.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this._handleKeyDownforsearch = this._handleKeyDownforsearch.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.SearchClicked = this.SearchClicked.bind(this);
    this.getNaturalSearchData = this.getNaturalSearchData.bind(this);
    // this.selectAllAsset = this.selectAllAsset.bind(this);
    this.selectButton = this.selectButton.bind(this);

    this.columns = [
      {
        field: 'erpExtAssetId',
        title: 'External Asset Id',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link:'assetLink',
      },
      {
        field: 'erpAssetId',
        title: 'Assetic ID',
        // width: 360,
        type: 'string',
        allowHiding: true,
        ...(this.props.selectMode ? {} : { link: 'asseticassetLink' }),
      },
      {
        field: 'AssetName',
        title: 'Asset Name',
        // width: 360,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'Description',
        title: 'Description',
        // width: 360,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },

      {
        field: 'typeTitle',
        title: 'Asset Type',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'subtypeTitle',
        title: 'Asset Sub Type',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'classTitle',
        title: 'Asset Class',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'subclassTitle',
        title: 'Asset Sub Class',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'Location',
        title: 'Location',
        // width: 120,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'maintenanceAssetType',
        title: 'Maintenance Type',
        // width: 120,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'maintenanceAssetSubType',
        title: 'Maintenance Sub Type',
        // width: 120,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'accntSystemId',
        title: 'Project ID',
        // width: 120,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'activityId',
        title: 'Activity ID',
        // width: 120,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'taskId',
        title: 'Task ID',
        // width: 120,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'Category',
        title: 'Category',
        // width: 120,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'workGroups',
        title: 'Work Group',
        // width: 120,
        type: 'string',
        allowHiding: true
      },

      {
        field: 'Status',
        title: 'Status',
        // width: 180,
        type: 'string',
        allowHiding: true
      }
    ];
    this.state = {
      items: _items,
      assetitems: [],
      class: [],
      columns: this.columns,
      selected: selectedtab,
      showAllAsset: false,
      showActiveAssets: true,
      totalResults: 100,
      asset: '',
      AssetNumber: '',
      assetStatusItems: '',
      AssetClassItems: '',
      AssetType: '',
      disabledAT: true,
      disabledSC: true,
      selectedItemKeys: []
    };
  }


  public async componentDidMount() {

    let tempstate = localStorage.getItem('assetState')
    this.setState({ loadingFields: true });
    await Promise.all([
      this._getAssetStatusData(),
      this._getAssetTypesData(),
      this._getAssetClassData()
    ]);
    this.setState({ loadingFields: false });
    if (tempstate) {
      let allState = JSON.parse(tempstate);

      this.setState({
        asset: allState.asset ? allState.asset : '',
        SearchPath: allState.SearchPath ? allState.SearchPath : '',
        assetStatus: allState.assetStatus ? allState.assetStatus : '',
        naturalSearch: allState.naturalSearch ? allState.naturalSearch : '',
        selectedTab: allState.selectedTab ? allState.selectedTab : 0,
        AsseticAssetId: allState.AsseticAssetId ? allState.AsseticAssetId : '',
        AssetID: allState.AssetID ? allState.AssetID : '',
        assetdesc: allState.assetdesc ? allState.assetdesc : '',
        AssetTypeItem: allState.AssetTypeItem ? allState.AssetTypeItem : '',
        AssetClassItem: allState.AssetClassItem ? allState.AssetClassItem : '',
        AssetSubClassItem: allState.AssetSubClassItem ? allState.AssetSubClassItem : '',
        subAssetTypeItem: allState.subAssetTypeItem ? allState.subAssetTypeItem : ''
      })
    }
    this.setState({ heading: this.props.heading })

  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getAssetwithcriteria();
    }
  }
  public getMultiselectProps(data: any, listData: any) {
    let returnData: Array<any> = [];
    if (this.state[listData] && data) {
      data.forEach((item: any) => {
        let tempItem = this.state[listData].find((l: any) => l.id === item.id);
        returnData.push(tempItem);
      })
    }
    return returnData;
  }
  public _handleKeyDownforsearch = (e: any) => {

    if (e.key === 'Enter') {
      this.getNaturalSearchData();
    }
  }

  public selectButton() {
    let selectAssetfromItems = this.state.items;
    let tempSelectedAssets = selectAssetfromItems.filter((a: any) => a.Selected === true);
    // let columns = this.state.columns;
    this.props.onClick(tempSelectedAssets);

  }
  public clearFilter() {
    this.setState({
      items: [],
      asset: '',
      SearchPath: '',
      assetStatus: '',
      subAssetTypeItem: '',
      naturalSearch: '',
      tempUnitTypeItems: this.state.UnityTypeItems,
      AssetTypeItem: '',
      assetdesc: '',
      AssetID: '',
      AssetSubClassItem: '',
      AssetClassItem: '',
      totalResults: 100,
      showAllAsset: false,
      showActiveAssets: true,
    });
    localStorage.setItem('assetState', '');
    let datagrid = localStorage.getItem('datagridFilterValue');
    if (datagrid) {
      localStorage.removeItem('datagridFilterValue');
    }
  }
  public selectItem(i: any) {
    let item = i;
    if (this.props.selectMode) {
      this.props.onClick(item);
      // this.selectAsset(item);
      return;
    }
    if (this.props.selectScheduleMode) {
      this.props.onClick(item);
      return;
    }
  }


  public async _getAssetStatusData() {
    try {
      const data: any = await utility.genericGetAPICall(Config.assetStatus);
      let AssetStatus = [];
      AssetStatus = data
        .filter((r: any) => r.isActive === true)
        .map((r: any) => ({ id: r.id, text: r.title, 'Select All': r.title }));
      const sortedAssetStaus = utility._sortItems(AssetStatus, 'text', false);

      this.setState({
        assetStatusItems: sortedAssetStaus,
        allassetStatusItems: sortedAssetStaus
      });
    } catch (err) {
      console.log(err);
    }
  }
  public async _getAssetClassData() {
    let apiName = `${Config.assetClass}?showAll=true`;
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      let Assetclasses = [];
      let Assetsubclasses = [];
      Assetclasses = data
        .filter((r: any) => r.isActive === true && r.type === 'C')
        .map((r: any) => ({ id: r.id, text: r.title, type: r.type, level: r.level }));
      const sortedAssetclasses = utility._sortItems(Assetclasses, 'text', false);
      Assetsubclasses = data
        .filter((r: any) => r.isActive === true && r.type === 'SC')
        .map((r: any) => ({ id: r.id, text: r.title, type: r.type, level: r.level }));
      const sortedAssetsubclasses = utility._sortItems(Assetsubclasses, 'text', false);
      this.setState({
        AssetClassItems: sortedAssetclasses,
        allAssetClassItems: sortedAssetclasses,
        AssetSubClassItems: sortedAssetsubclasses,
        allAssetSubClassItems: sortedAssetsubclasses,
        disabledSC: true
      });

    } catch (err) {
      console.log(err);
    }
  }
  public async _getAssetTypesData() {
    let apiName = `${Config.assetType}?showAll=true`;
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      let AssetType = [];
      let AssetSubType = [];
      AssetType = data
        .filter((r: any) => r.isActive === true && r.type === 'T')
        .map((r: any) => ({ id: r.id, text: r.title, type: r.type, level: r.level }));
      const sortedAssetType = utility._sortItems(AssetType, 'text', false);
      AssetSubType = data
        .filter((r: any) => r.isActive === true && r.type === 'ST')
        .map((r: any) => ({ id: r.id, text: r.title, type: r.type, level: r.level }));
      const sortedAssetSubType = utility._sortItems(AssetSubType, 'text', false);
      this.setState({
        AssetType: sortedAssetType,
        allAssetType: sortedAssetType,
        subAssetType: sortedAssetSubType,
        allsubAssetType: sortedAssetSubType,
        disabledAT: true
      });
    } catch (err) {
      console.log(err);
    }
  }
  public getNaturalSearchData() {
    if (!this.state.naturalSearch) {
      this.setState({
        alertMessage: 'Please enter your search',
        showAlert: true
      });
      return;
    }
    let utterance = this.state.naturalSearch.replace('&', '\\0026');

    let naturalSearchCriteria = `AssetSearchIntent?utterance=${utterance}`

    this.setState({ loading: true, loadingResult: true });

    utility.genericGetAPICall(naturalSearchCriteria).then((resultRequests: any) => {
      if (resultRequests) {
        this.getAssetwithNaturalSearch(resultRequests);

      }
    }

    ).catch((err) => {
      console.log(err);

      this.setState({
        loading: false,
        loadingResult: false,
        alertMessage: "Utterance is not related to Search Asset",
        showAlert: true,
        assetSearchCount: 0
      });
    });

  }

  private onAssetsSelected = ((rowData:any) => {
    if(rowData && rowData.length > 0){
      let selectedItemsToAdd:any = [];
      rowData.forEach((element: any) => {
          selectedItemsToAdd.push(element);
      });
      this.props.onAssetsSelected(selectedItemsToAdd);
    }
  });

  public getAssetwithNaturalSearch(assetCriteria: any) {

    let unitType = JSON.parse(assetCriteria.UnitType);
    unitType = unitType && unitType.join().replace("&", '\\0026');
    let pageNumber = 1;
    let pageSize = this.state.totalResults ? this.state.totalResults : 200;
    let assetsCriteia = `/Assets/Natural?unitType=${unitType}&site=${assetCriteria.Site}&addressLatLong=&distanceUnit=${assetCriteria.DistanceUnit}&distanceValue=${assetCriteria.DistanceValue}&pageNumber=${pageNumber}&pageSize=${pageSize}&showAll=${this.state.showAllAsset}`

    this.setState({
      loading: true
    });

    utility.genericGetAPICall(assetsCriteia).then((assetItems: any) => {

      _items = [];
      if (assetItems.length === 0) {
        this.setState({
          alertMessage: 'No results found for this search',
          showAlert: true,
          items: _items,
          loading: false,
          loadingResult: false
        });
        return;
      }
      else {
        assetItems.forEach((item: any) => {
          _items.push({
            Register: item.registerTitle,
            AssetNumber: item.id,
            AssetID: item.id,
            id: item.id,
            masterAssetId: item.masterAssetId,
            parentAssetId: item.masterAssetId,
            AssetType: item.typeTitle,
            AssetTypeId: item.typeId,
            AssetClass: item.classTitle,
            activityId: item.activityId,
            taskId: item.taskId,
            AssetName: item.title,
            accntSystemId: item.accntSystemId,
            erpAssetId: item.erpAssetId,
            erpExtAssetId: item.erpExtAssetId !== null ? item.erpExtAssetId : item.id,
            erpExtAssetName: item.erpExtAssetName,
            Status: item.assetStatus,
            Description: item.description,
            Location: item.locationDescription,
            maintenanceAssetType: item.maintenanceAssetType,
            maintenanceAssetSubType: item.maintenanceAssetSubType,
            projectCode: item.projectCode,
            erpExtSystemName: item.erpExtSystemName,
            functionalLocationL1Id: item.functionalLocationL1Id,
            functionalLocationL1Name: item.functionalLocationL1Name,
            functionalLocationL1Type: item.functionalLocationL1Type,
            functionalLocationL2Id: item.functionalLocationL2Id,
            functionalLocationL2Name: item.functionalLocationL2Name,
            functionalLocationL2Type: item.functionalLocationL2Type,
            Category: item.assetCategoryTitle,
            assetCriticality: item.assetCriticality,
            departmentTitle: item.departmentTitle,
            assetsubclass: item.subclassTitle,
            teamTitle: item.teamTitle,
            typeTitle: item.typeTitle,
            subtypeTitle: item.subtypeTitle,
            workGroupIds: item.workGroupIds,
            subclassTitle: item.subclassTitle,
            classTitle: item.classTitle,
            Selected: false

          });
        });
        this.setState({
          items: _items,
          loading: false,
          loadingResult: false,
          callAfterInit: true,
          assetSearchCount: assetItems.length
        });
        var stateData = { ...this.state };
        localStorage.setItem('assetState', JSON.stringify(stateData));
        //window.scrollTo(0, this.myRef.current.offsetTop);
      }

    }, (err: any) => {
      console.log(err);
      this.setState({
        loading: false,
        loadingResult: false,
        alertMessage: "No result found. Please try again with different search",
        showAlert: true,
        assetSearchCount: 0
      });
    });
  }

  public getAssetwithcriteria() {
    let criteriaSelected = false;

    let dataToPassToService: any = {};
    if (this.state.assetname) {
      dataToPassToService.title = this.state.assetname;
      criteriaSelected = true;
    }
    if (this.state.AssetID) {
      dataToPassToService.assetId = this.state.AssetID;
      criteriaSelected = true;
    }
    if (this.state.assetStatus && !this.state.showActiveAssets) {
      dataToPassToService.assetStatus = this.state.assetStatus.text;
      criteriaSelected = true;
    }
    if (this.state.subAssetTypeItem && this.state.subAssetTypeItem.length > 0) {
      let tempsubAssetTyp: any = [];
      this.state.subAssetTypeItem.forEach((element: any) => {

        tempsubAssetTyp.push(+element);
      });
      dataToPassToService.subTypeIds = tempsubAssetTyp;
      criteriaSelected = true;
    }
    if (this.state.AssetTypeItem) {
      dataToPassToService.typeId = +this.state.AssetTypeItem.id;
      criteriaSelected = true;
    }
    if (this.state.AssetSubClassItem && this.state.AssetSubClassItem.length > 0) {
      let tempAssetSubClass: any = [];
      this.state.AssetSubClassItem.forEach((element: any) => {

        tempAssetSubClass.push(+element);
      });
      dataToPassToService.subClassIds = tempAssetSubClass;
      criteriaSelected = true;
    }

    if (this.state.AssetClassItem) {
      dataToPassToService.classId = +this.state.AssetClassItem.id;
      criteriaSelected = true;
    }
    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }
    this.setState({
      loading: true
    });

    let pageNumber = 1;
    let pageSize = this.state.totalResults ? this.state.totalResults : 100;
    let searchAssetAPi = `${Config.AssetSearchAPI}?fetchOnlyActiveAssets=${this.state.showActiveAssets}&fetchDraftAssets=${this.state.showActiveAssets}&pageNumber=${pageNumber}&pageSize=${pageSize}&showAll=${this.state.showAllAsset}`
    utility.genericPostAPIcall(searchAssetAPi, dataToPassToService).then((data: any) => {
      _items = [];
      if (data === null || data.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true, items: _items, loading: false
        });
        return;
      }
      else {
        data.forEach((item: any) => {
          let coordinates = item.coordinates ? JSON.parse(item.coordinates) : '';
          if (coordinates && coordinates.coordinates?.length > 0) {
            coordinates = { lat: coordinates.coordinates[1], lng: coordinates.coordinates[0] }
          }
          let workGrpTitle: String = '';
          if (item.workGroups && item.workGroups.length > 0) {
            item.workGroups.forEach((workGrp: any) => {
              workGrpTitle += workGrp.title + ', ';
            });
            workGrpTitle = workGrpTitle.substring(0, workGrpTitle.length - 1);
          }
          _items.push({
            Register: item.registerTitle,
            AssetNumber: item.id,
            AssetID: item.id,
            id: item.id,
            masterAssetId: item.masterAssetId,
            parentAssetId: item.masterAssetId,
            AssetType: item.typeTitle,
            activityId: item.activityId,
            taskId: item.taskId,
            AssetTypeId: item.typeId,
            AssetClass: item.classTitle,
            AssetName: item.title,
            accntSystemId: item.accntSystemId,
            erpAssetId: item.erpAssetId,
            erpExtAssetId: item.erpExtAssetId !== null ? item.erpExtAssetId : item.id,
            erpExtAssetName: item.erpExtAssetName,
            Status: item.assetStatus,
            workGroups: workGrpTitle,
            Description: item.description,
            Location: item.locationDescription,
            maintenanceAssetType: item.maintenanceAssetType,
            maintenanceAssetSubType: item.maintenanceAssetSubType,
            projectCode: item.projectCode,
            erpExtSystemName: item.erpExtSystemName,
            functionalLocationL1Id: item.functionalLocationL1Id,
            functionalLocationL1Name: item.functionalLocationL1Name,
            functionalLocationL1Type: item.functionalLocationL1Type,
            functionalLocationL2Id: item.functionalLocationL2Id,
            functionalLocationL2Name: item.functionalLocationL2Name,
            functionalLocationL2Type: item.functionalLocationL2Type,
            Category: item.assetCategoryTitle,
            assetCriticality: item.assetCriticality,
            departmentTitle: item.departmentTitle,
            assetsubclass: item.subclassTitle,
            teamTitle: item.teamTitle,
            typeTitle: item.typeTitle,
            subtypeTitle: item.subtypeTitle,
            workGroupIds: item.workGroupIds,
            subclassTitle: item.subclassTitle,
            classTitle: item.classTitle,
            Selected: false,
            coordinates: item.coordinates ? coordinates : { lat: 0, lng: 0 }

          });
        });
      }

      if (_items.length === 1 && !this.props.selectMode) {
        window.open(`#/AssetDetail?AssetID=${_items[0].AssetID}`, '_self')
      }

      let tempSelectedAssets = _items.filter((a: any) => a.Selected === true);

      this.setState({
        items: _items,
        loading: false,
        callAfterInit: true,
        itemSelected: tempSelectedAssets.length
      });

      var stateDate = { ...this.state };
      localStorage.setItem('assetState', JSON.stringify(stateDate));
      //console.log(_items);
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });

  }
  private SearchClicked() {
    //console.log("mapseacrh clicke");
    this.setState(
      {
        iFramePanelOpened: true,
        selectModeMap: false,
        selectParent: true,
        selectMapDetail: false,
      });

  }
  public renderAssetMap() {
    return (
      <AssetMap
        selectAssetsforParent={(Asset: any) => this.selectAssetsonMapforParent(Asset)}
        iFramePanelOpened={this.state.iFramePanelOpened}
        onClose={() => this._onDismissMap()}
        selectParent={this.state.selectParent}
        selectModeMap={this.state.selectModeMap}
      // selectMapDetail={this.state.selectMapDetail}
      />
    );
  }
  public _onDismissMap() {
    this.setState({
      iFramePanelOpened: false
    });
  }

  public selectAssetsonMapforParent(selectedAsset: any) {
    let Asset = selectedAsset[0];
    let properties = JSON.parse(Asset.properties);
    console.log('properties', Asset.properties)
    this.setState({
      parentId: Asset.id ? Asset.id : properties.ASSET_ID,
      parentDetail: Asset.name,
      iFramePanelOpened: false,
      selectModeMap: false,
      selectParent: false,
      selectMapDetail: false,
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  private handleValueChange = (event: any, fieldName: string) => {
    this.setState({ [fieldName]: event.value });
  }
  public handleDropdownChange(e: any, fieldName: string) {
    if (e.selectedItem !== null) {
      if (fieldName === 'AssetClassItem') {
        this.setState({ AssetSubClassItems: [] });
        const filteredSCitems = this.state.allAssetSubClassItems.filter((item: any) => item.type === "SC" && (item.level && item.level.includes(e.selectedItem.level)))
        this.setState({ AssetSubClassItems: filteredSCitems, disabledSC: false });
      }
      if (fieldName === 'AssetTypeItem') {
        this.setState({ subAssetType: [] });
        const filteredATitems = this.state.allsubAssetType.filter((item: any) => item.type === "ST" && (item.level && item.level.includes(e.selectedItem.level)));
        this.setState({ subAssetType: filteredATitems, disabledAT: false });
      }

    }
    this.setState({ [fieldName]: e.selectedItem });
  }
  private _multiFieldChanged = (event: any, fieldName: string) => {
    this.setState({ [fieldName]: event });
  }
  public handleSelect = (event: any, newValue: any) => {
    selectedtab = newValue;
    this.setState({ selected: newValue });
    //this.clearFilter();
  }
  public render(): React.ReactElement<any> {

    return (
      <React.Fragment>
        <div>
          {this.renderAssetMap()}
          {this.state.showAlert && this.alert()}
          {!this.props.selectMode && (
            <div className={this.state.heading === undefined ? "SearchDiv" : ''}>
              <div className={'headingDiv'}>
                <span className={"heading"}> Search Assets</span>
              </div>
            </div>
          )}
          <div className={"tabsDiv assetSearch"}>
            <Paper square>

              <Tabs
                value={this.state.selected ? this.state.selected : 0}
                onChange={this.handleSelect}
                indicatorColor="primary"
                // textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label={<span className='tabHeading'><PageviewOutlinedIcon className={"subMenuLinkIcon"} />Advanced Search</span>}  {...a11yProps(0)} />
                {(!this.props.selectWO && !this.props.selectWO) && <Tab label={<span className='tabHeading'><MapOutlinedIcon className={"subMenuLinkIcon"} />Map Search</span>}  {...a11yProps(1)} />}
              </Tabs>
            </Paper>
            <TabPanel value={this.state.selected ? this.state.selected : 0} index={0}>
              <br />
              <div className={"searchFieldDiv "}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextBox
                      stylingMode='underlined'
                      label="Asset ID"
                      value={this.state.AssetID}
                      showClearButton={true}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'AssetID')}
                      onKeyDown={(e: any) => this._handleKeyDown(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextBox
                      stylingMode='underlined'
                      label="Asset Name"
                      value={this.state.assetname}
                      showClearButton={true}
                      onValueChanged={(e: any) => this.handleValueChange(e, 'assetname')}
                      onKeyDown={(e: any) => this._handleKeyDown(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.AssetType}
                      selectedItem={this.state.AssetTypeItem}
                      label='Asset Type'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'AssetTypeItem')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='multiSelect-dropdown'>
                    <MultiSelectTreeView
                      items={this.state.subAssetType}
                      onChange={(e: any) => this._multiFieldChanged(e, 'subAssetTypeItem')}
                      selectedItem={this.state.subAssetTypeItem ? this.state.subAssetTypeItem : ''}
                      label="Asset SubType"
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      disabled={this.state.disabledAT ? true : false}
                      showClearButton={true}
                      placeholder="Select a value..."
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.AssetClassItems}
                      selectedItem={this.state.AssetClassItem}
                      label='Asset Class'
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'AssetClassItem')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='multiSelect-dropdown'>

                    <MultiSelectTreeView
                      items={this.state.AssetSubClassItems}
                      onChange={(e: any) => this._multiFieldChanged(e, 'AssetSubClassItem')}
                      selectedItem={this.state.AssetSubClassItem ? this.state.AssetSubClassItem : ''}
                      label="Asset Subclass"
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      disabled={this.state.disabledSC ? true : false}
                      showClearButton={true}
                      placeholder="Select a value..."
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <SelectBoxInput
                      stylingMode='underlined'
                      displayExpr="text"
                      valueExpr="id"
                      showClearButton={true}
                      items={this.state.assetStatusItems}
                      selectedItem={this.state.assetStatus}
                      label='Status'
                      disabled={this.state.showActiveAssets ? true : false}
                      onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'assetStatus')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} style={{display:'flex'}}>
                  <CheckBoxInput value={this.state.showActiveAssets} onValueChanged={(e: any) => this.handleValueChange(e, 'showActiveAssets')} text={'Show Active Assets'} />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <div className={"searchButtonDiv displayFlex"}>
                      {/* {this.state.totalRecords && <div className="totalRecordsDiv">Total records available for this search is {this.state.totalRecords}</div>} */}
                      <div>
                        <NumberBoxInput
                          stylingMode='underlined'
                          label="Total Results to Display"
                          min={1}
                          value={this.state.totalResults}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'totalResults')}
                        />
                      </div>
                      <div>
                        <Button onClick={this.clearFilter} variant='outlined'>
                          Clear
                        </Button>
                        <Button onClick={this.getAssetwithcriteria} variant='outlined' color="primary" className={'button'}>
                          Search
                        </Button>
                        <CheckBoxInput value={this.state.showAllAsset} onValueChanged={(e: any) => this.handleValueChange(e, 'showAllAsset')} text={'Show All Assets'} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            {(!this.props.selectWO && !this.props.selectWO) &&
              (<TabPanel value={this.state.selected ? this.state.selected : 0} index={1}>
                <div className='pb-20' style={{ height: '80vh' }}>
                  <AssetMapforDetail
                    selectMode={this.props.selectMode}
                    selectAssets={(e: any) => this.props.onClick(e)}
                    location={this.props.location}
                  />
                </div>
              </TabPanel>)}


            {!this.state.loading ?
              <div className=''>
                {(this.state.items && this.state.items.length > 0) && (this.state.selected !== 1) && (
                  <DataTable
                    style={{ width: '100%' }}
                    filteralbe={true}
                    groupable={true}
                    rows={this.state.items}
                    columns={this.state.columns}
                    fileName={'Asstes'}
                    columnChooser={'assetColumns'}
                    selectionMode={this.props.selectWO ? 'multiple' : false}
                    showHideColumnMenu={true}
                    callAfterInit={this.state.callAfterInit}
                    stopCommsAfterInit={() => this.stopCommsAfterInit()}
                    loading={this.state.loading}
                    allowExportSelectedData={false}
                    showNewBtn={false}
                    // viewItem={(a:any) => this.viewDetials(a)}
                    // showNewModal = {() => this._showModal()}
                    // deleteItem = {(a:any) => this.DeleteDialog(a)}
                    // editItem = {(a:any) => this.EditItemModal(a)}
                    showCommandBtn={false}
                    showViewDetailBtn={false}
                    selectItem={(e: any) => this.selectItem(e)}
                    selectMode={this.props.selectMode}
                    selectScheduleMode={this.props.selectScheduleMode}
                    selectCreateScheduleMode={this.props.selectCreateScheduleMode}
                    selectedRowKeys={this.props.selectWO ? this.state.selectedItemKeys : ''}
                    onSelectionChanged={this.props.selectWO ? this.onAssetsSelected : ''}
                    customSelectRows={this.props.selectWO ? true : false}
                    customToolbarButtonText={this.props.selectWO ? 'Select Asset' : ''}
                    toolbarItemsVisible={true}
                  />
                )}
              </div>
              :
              // <CircularProgress className="loader" />
              <Spinner size='large' label={'Loading..'} className="loader" />
            }
          </div>

        </div>
      </React.Fragment>
    );
  }


  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }



}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>

  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index: any) {

  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
